<template>
	<Disclosure
		v-slot="{ close }"
		as="nav"
		class="fixed top-0 z-30 w-full bg-mx-navy md:hero-image hero-lengths"
	>
		<section class="container px-4 mx-auto md:py-4">
			<div class="flex justify-end h-16 px-4 whitespace-nowrap md:justify-between">
				<div class="flex w-full">
					<NavLogo @close="close()" />
					<NavDesktopMainLinks class="w-full" />
				</div>
				<NavDesktopAccountLinks
					:is-logged-in="loggedIn"
					:account-url="accountUrl"
				/>
				<LazyNavMobileHamburgerMenu />
			</div>
		</section>
		<NavDrawer
			:is-logged-in="loggedIn"
			:account-url="accountUrl"
			@nav-link-clicked="handleNavigationClick(close)"
		/>
	</Disclosure>
</template>

<script setup lang="ts">
import { Disclosure } from '@headlessui/vue'
import { useAuth } from '@/composables/useAuth'

const { loggedIn, accountUrl } = useAuth()

const handleNavigationClick = (close: Function) => {
	close()
}
</script>
